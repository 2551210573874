<template>
  <div>
    <header-component />
    <div><router-view /></div>
    <footer-component />
  </div>
</template>
<script>
import { useStore } from 'vuex'
import FooterComponent from '@/components/partials/FooterComponent.vue'
import HeaderComponent from '@/components/partials/HeaderComponent.vue'
export default {
  name: 'Home',
  components: {
    FooterComponent,
    HeaderComponent,
  },
  setup() {
    const store = useStore()
    store.commit('app/SET_API_BASEURL', process.env.VUE_APP_BASE_API)
    return {}
  },
}
</script>
