<template>
  <section class="section section-form">
    <div class="section-form__header">
      <intro/>
    </div>
    <div class="section-form__body">
      <form-campaign/>
    </div>
  </section>
</template>

<script>
import Intro from '@/components/Intro.vue'
import FormCampaign from '@/components/FormCampaign.vue'
export default {
  name: 'Fiche',
  components: {
    Intro,
    FormCampaign
  },
}
</script>

<style></style>
