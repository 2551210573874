<template>
  <div class="footer mt-4">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p>
            Groupama Gan Vie - Entreprise gestionnaire des produits d’assurance vie
            distribués par les réseaux de distribution Gan Assurances, Gan
            Eurocourtage et Groupama <br />
            SA au capital de 1 371 100 605 € – RCS Paris 340 427 616 – APE : 6511Z -
            Siège social : 8-10 rue d’Astorg – 75383 Paris Cedex 08 – <br />
            Entreprise régie par le code des assurances et soumise à l’Autorité de
            Contrôle Prudentiel et de Résolution (ACPR) - 4 place de Budapest – CS
            92459 – 75436 Paris Cedex 09<br />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
