import axios from 'axios'
//import store from '@/store'
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000, // request timeout
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    //let accessToken = store.state.accessToken
    //config.headers.Authorization = `Bearer ${accessToken}`
    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  async (response) => {
    if (
      response.status != 200 &&
      response.status != 201 &&
      response.status != 204
    ) {
      return Promise.reject(new Error(response.message || 'Error'))
    } else {
      return response
    }
  },
  async (error) => {
    return Promise.reject(error)
  }
)

export default service
