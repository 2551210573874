<template>
  <header class="header">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="logo">
            <a href="https://www.groupama.fr/" target="_blank"
              ><img src="@/assets/logo-ggvie.svg" class="img-fuild"
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row header__border">
        <div class="col-lg-6 col-md-6 col-sm-6 header__border-left w-50"> </div>
        <div class="col-lg-6 col-md-6 col-sm-6 header__border-right w-50">
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {}
</script>

<style></style>
