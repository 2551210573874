import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Fiche from '@/views/Fiche.vue'

const routes = [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
        path: '/#/fiche',
        name: 'Fiche',
        component: Fiche,
    },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
