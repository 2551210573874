<template>
  <section class="section section-form">
    <div class="section-form__header">
      <div class="container">
        <div class="row">
          <div class="col-12 pt-5">
            <h1 class="mt-4 title">
              Groupama Gan Vie <br />
              Opération de fiabilisation de vos informations personnelles
            </h1>
            <h5 class="mt-5 text-center"
              >Groupama Gan Vie est l’entreprise gestionnaire des produits
              d’assurance vie commercialisés par les réseaux de distribution Gan
              Assurances, Gan Eurocourtage et Groupama</h5
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  methods: {
    goToFormCampaigne() {
      this.$router.push({ name: 'FormCampaign' })
    },
  },
}
</script>
