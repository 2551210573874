<template>
  <div class="container">
    <div class="row">
      <div class="col-12 pt-5">
        <h1 class="mt-4 title">
          Groupama Gan Vie <br />
          Opération de fiabilisation de vos informations personnelles
        </h1>
        <h5 class="mt-4"
          >Groupama Gan Vie est l’entreprise gestionnaire des produits
          d’assurance vie commercialisés par les réseaux de distribution Gan
          Assurances, Gan Eurocourtage et Groupama</h5
        >
        <h6 class="text-muted mt-4"
          >Il est important de mettre à jour vos coordonnées afin que vous
          puissiez continuer à recevoir vos relevés de droits retraite acquis au
          titre de votre contrat retraite supplémentaire géré par Groupama Gan
          Vie et souscrit par votre actuel ou ancien employeur comme précisé
          dans le formulaire suivant et dans l’attestation sur l’honneur
          transmise dans le cadre de cette campagne de communication si vous
          avez été sollicité par courrier.</h6
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Intro',
}
</script>

<style></style>
