import request from '@/utils/request'

export function storeFormInformations(data) {
  return request({
    url: '/fiches/' + data,
    method: 'get',
    params: {},
  })
}
export function resendEmailSignature(data) {
  return request({
    url: '/certificates/resend',
    method: 'post',
    data: data,
  })
}
export function updateFicheInformations(data) {
  return request({
    url: '/fiches/' + data.id,
    method: 'put',
    data: data,
  })
}
export function signature(data) {
  return request({
    url: '/procedures',
    method: 'post',
    params: data.output,
    data: data.data,
  })
}
export function getAdresse(data) {
  return request({
    url: 'https://api-adresse.data.gouv.fr/search',
    method: 'get',
    params: data,
  })
}
export function signatureFile(data) {
  return request({
    url: 'certificates/'+ data +'/download',
    method: 'get',
    responseType: 'blob'
  })
}
export function reportinvalidToken(data) {
  console.log('reportinvalidToken data', data)
  return request({
    url: 'wrong-token-email',
    method: 'post',
    data: data
  })
}