import { SET_API_BASEURL, SET_FORM_INFORMATION } from '@/store/mutation-types'
import {
  storeFormInformations as storeFormInformationsAPI,
  updateFicheInformations as updateFicheAPI,
  signature as signatureAPI,
  getAdresse as adresseAPI,
  signatureFile as signatureFileApi,
  reportinvalidToken as reportinvalidTokenApi,
  resendEmailSignature
} from '@/api/app'
const state = () => ({
  apiBaseURL: '',
  fiche: {},
})
const getters = {}
// actions
const actions = {
  async resendEmailSignature({ commit }, params) {
    const response = await resendEmailSignature(params)
    return response
  },
  async reportWrongToken({ commit }, params) {
    const response = await reportinvalidTokenApi(params)
    return response
  },
  async fetchFormInformations({ commit }, params) {
    const response = await storeFormInformationsAPI(params)
    if (response.data) {
      console.log('formInformations = ', response)
      commit('SET_FORM_INFORMATION', response.data)
    }

    return response
  },
  async updateFiche({ commit }, params) {
    const response = await updateFicheAPI(params)
    if (response.data) {
      commit('SET_FORM_INFORMATION', response.data)
    }

    return response
  },
  async launchSignature({ commit }, params) {
    console.log('launchSignature params', params)
    const response = await signatureAPI(params)
    if (response.data) {
      console.log('launchSignature = ', response)
    }
    return response
  },
  async getAdresse({ commit }, params) {
    const response = await adresseAPI(params)
    return response.data
  },
  async getSignatureFile({ commit }, params) {
    const response = await signatureFileApi(params)
    return response.data
  },
}
// mutations
const mutations = {
  [SET_FORM_INFORMATION](state, data) {
    state.fiche = data.data
    state.fiche.birthday = state.fiche.birthday.replaceAll('-', '/')
  },
  [SET_API_BASEURL](state, data) {
    state.apiBaseURL = data
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
